import {
  Button,
  Container,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { MarketingUserDto } from "../../sdk/open-api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AppColours } from "../../helpers/colors";

type PaymentSuccessProps = {
  user: MarketingUserDto | null;
};

export default function PaymentSuccessHome(props: PaymentSuccessProps) {
  const { user } = props;
  return (
    <Container
      maxWidth="lg"
      id="lead-magnet-pay-success-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 15, sm: 18 },
        pb: { xs: 6, sm: 6 },
      }}
    >
      {user && (
        <Stack spacing={{ xs: 2, sm: 3 }} useFlexGap>
          <Typography variant="h5" gutterBottom color="primary">
            {`🎉 Congratulations,  ${user?.first_name}. You're officially a Shareduled user for LIFE!`}
          </Typography>
          <Typography variant="body1">
            {`We are thrilled to have you onboard as a lifetime member. You’ve made a fantastic decision, and we can’t wait to see how Shareduled transforms your scheduling experience.`}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
            }}
          >
            500
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Here’s What Happens Next:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            1.&nbsp;
            <strong>
              <em>Check Your Email</em>
            </strong>
          </Typography>

          <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
            {[
              `We’ve sent a confirmation email to ${
                user?.business_email ? user?.business_email : "your email"
              }`,
              "Inside, you’ll find your lifetime access code and all the details you need to get started.",
            ].map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body1" style={{ fontWeight: "300" }}>
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            2.&nbsp;
            <strong>
              <em>Stay Tuned for Updates</em>
            </strong>
          </Typography>
          <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
            {[
              `We’ll keep you informed with the latest news and updates as we approach our launch date on September 27th 2024.`,
              "Be on the lookout for exclusive early adopter content and tips to maximize your Shareduled experience.",
            ].map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body1" style={{ fontWeight: "300" }}>
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            3.&nbsp;
            <strong>
              <em>Join Our Community</em>
            </strong>
          </Typography>
          <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
            {[
              `Connect with other early adopters and Shareduled users.`,
              "Share your feedback, ask questions, and be a part of shaping the future of our platform.",
            ].map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body1" style={{ fontWeight: "300" }}>
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <br />
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Your Lifetime Access Includes:
          </Typography>
          <List sx={{}}>
            {[
              "Unlimited Use|All features, all updates, forever.",
              "Priority Support|Get help faster with our dedicated support team.",
              "Early Feature Access|Be the first to try out new features and improvements.",
            ].map((x, index) => {
              const [header, body] = x.split("|");
              return (
                <ListItem disablePadding key={index.toString()}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "300",
                        textAlign: "left",
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    >
                      <strong>
                        <em>{header}</em>
                      </strong>
                      : &nbsp; {body}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Need Help?
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "300", mb: { xs: 4, sm: 5, md: 6 } }}
          >
            If you have any questions or need assistance, our support team is
            here for you. Reach out to us anytime at&nbsp;
            <Link
              color="inherit"
              href="mailto:support@shareduled.com?subject=Question About Shareduled&body=Hello Team Shareduled,"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@shareduled.com
            </Link>
          </Typography>

          <Button
            color="primary"
            variant="contained"
            href=" https://www.facebook.com/groups/sharedulers"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              alignSelf: "center",
              borderRadius: "8px",
              textTransform: "none",
              backgroundColor: AppColours.MainGreen,
              padding: "12px",
              width: "100%",
            }}
          >
            Join the Community
          </Button>
        </Stack>
      )}
      {!user && (
        <Typography variant="subtitle1" color="primary">
          Hi there! We could not find your user!
        </Typography>
      )}
    </Container>
  );
}
