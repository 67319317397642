import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { Button, FormControl, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function StripeCheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Unknown payment issue");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_FE_BASE_URL}/payment-success`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error?.message ?? "Card or validation error encountered");
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <FormControl
      id="payment-form"
      sx={{
        width: "100%",
        display: "flex",
      }}
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      {!isLoading && (
        <>
          <Button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            color="primary"
            variant="contained"
            sx={{ mt: { xs: 3, sm: 4 }, textTransform: "none" }}
            onClick={handleSubmit}
          >
            I Want To Pay Now for My Lifetime Access
          </Button>
          <Typography
            variant="caption"
            color="primary"
            id="payment-message"
            sx={{
              fontWeight: "300",
              textAlign: "center",
              pt: { xs: 2, sm: 3 },
            }}
          >
            Don’t let this opportunity slip through your fingers. The clock is
            ticking, and September 22nd is just around the corner.
          </Typography>
        </>
      )}

      {isLoading && (
        <CircularProgress
          id="stripe-spinner"
          color="primary"
          sx={{ alignSelf: "center", mt: { xs: 3, sm: 4 } }}
        />
      )}

      {/* Show any error or success messages */}
      {message && (
        <Typography
          variant="caption"
          color="primary"
          id="payment-message"
          sx={{
            fontWeight: "400",
          }}
        >
          {message}
        </Typography>
      )}
    </FormControl>
  );
}
