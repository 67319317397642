import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { CardActionArea, CardMedia, Link, Stack } from "@mui/material";
import {
  Blocker,
  BlockerFunction,
  useBlocker,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AppColours } from "../../helpers/colors";
import { routesForBlockerPopUp } from "../../helpers/miscellaneous";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useCallback } from "react";

export type AppBarComponentProps = {
  hideNavLinks?: boolean;
};

function ShouldNavigateComponent({ blocker }: { blocker: Blocker }) {
  if (blocker.state === "blocked") {
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: AppColours.LightPurple,
          alignSelf: "center",
          mt: { xs: 2, sm: 4 },
          pt: { xs: 2, sm: 2 },
          pb: { xs: 2, sm: 2 },
          borderRadius: 4,
        }}
      >
        <>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            useFlexGap
            sx={{
              width: "inherit",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              color={AppColours.MainBlack}
              variant="body2"
              gutterBottom
            >
              Leaving might make you lose your data. Proceed anyways?
            </Typography>

            <Box
              sx={{
                width: "inherit",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<CheckIcon sx={{ fontWeight: "bolder" }} />}
                onClick={() => blocker.proceed?.()}
                sx={{
                  width: "48%",
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                endIcon={<CloseIcon />}
                onClick={() => blocker.reset?.()}
                sx={{
                  width: "48%",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </>
      </Container>
    );
  }

  return null;
}

function AppBarComponent(props: AppBarComponentProps) {
  const { hideNavLinks } = props;

  const location = useLocation();
  const navigate = useNavigate();

  let shouldBlock = useCallback<BlockerFunction>(
    ({ historyAction, currentLocation, nextLocation }) => {
      console.log("\nHISTORY_ACTION: ", historyAction);
      console.log("CURRENT_LOCATION: ", currentLocation);
      console.log("NEXT_LOCATION: ", nextLocation);

      let shouldBlockNavigation = false;
      switch (historyAction) {
        case "POP":
        case "PUSH":
          shouldBlockNavigation =
            routesForBlockerPopUp.includes(currentLocation?.pathname) &&
            ![
              "/join-for-life",
              "/join-coming-soon",
              "/payment-success",
              "/our-gift",
            ].includes(nextLocation?.pathname);

          break;
      }

      return shouldBlockNavigation;
    },
    []
  );
  let blocker = useBlocker(shouldBlock);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position={location.pathname === "/" ? "static" : "fixed"}
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <Toolbar>
                <CardActionArea
                  style={{
                    width: 30,
                    borderRadius: 30,
                  }}
                  onClick={() => {
                    if (blocker && blocker.state !== "blocked") {
                      navigate("/");
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    loading="lazy"
                    image={require("../../assets/logodarkfull.jpg")}
                    style={{
                      height: 36,
                      width: 36,
                      borderRadius: 36,
                    }}
                  />
                </CardActionArea>

                <Typography
                  variant="body1"
                  noWrap
                  style={{ fontWeight: "bolder", marginLeft: "16px" }}
                  color="primary"
                >
                  Shareduled
                </Typography>
              </Toolbar>

              {!hideNavLinks && (
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <MenuItem
                    onClick={() =>
                      scrollToSection("service-excellence-section")
                    }
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="primary">
                      About Us
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToSection("business-features-section")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="primary">
                      Features
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {}} sx={{ py: "6px", px: "12px" }}>
                    <Link
                      underline="none"
                      href="mailto:mia.chloe@shareduled.com?subject=Question About Shareduled&body=Hello Team Shareduled,"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="body2" color="primary">
                        Contact
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToSection("faq-section")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="primary">
                      FAQ
                    </Typography>
                  </MenuItem>
                </Box>
              )}
            </Box>

            {!hideNavLinks && (
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    scrollToSection("shareduled-power-section");
                  }}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    backgroundColor: AppColours.MainRed,
                    p: 1,
                    pl: 2,
                    pr: 2,
                  }}
                >
                  Take Back Your Time: Start Today
                </Button>
              </Box>
            )}

            {!hideNavLinks && (
              <Box sx={{ display: { sm: "", md: "none" } }}>
                <Button
                  variant="text"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ minWidth: "30px", p: "4px" }}
                >
                  <MenuIcon />
                </Button>
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={toggleDrawer(false)}
                >
                  <Box
                    sx={{
                      minWidth: "60dvw",
                      p: 2,
                      backgroundColor: "background.paper",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        flexGrow: 1,
                      }}
                    ></Box>
                    <MenuItem
                      onClick={() =>
                        scrollToSection("service-excellence-section")
                      }
                    >
                      About Us
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        scrollToSection("business-features-section")
                      }
                    >
                      Features
                    </MenuItem>
                    <MenuItem onClick={() => {}}>
                      <Link
                        color="inherit"
                        underline="none"
                        href="mailto:mia.chloe@shareduled.com?subject=Question About Shareduled&body=Hello Team Shareduled,"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection("faq-section")}>
                      FAQ
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <Button
                        variant="contained"
                        component="a"
                        onClick={() => {
                          scrollToSection("shareduled-power-section");
                        }}
                        sx={{
                          width: "100%",
                          borderRadius: "8px",
                          textTransform: "none",
                          backgroundColor: AppColours.MainRed,
                        }}
                      >
                        Take Back Your Time: Start Today
                      </Button>
                    </MenuItem>
                  </Box>
                </Drawer>
              </Box>
            )}
          </Toolbar>

          {blocker ? <ShouldNavigateComponent blocker={blocker} /> : null}
        </Container>
      </AppBar>
    </div>
  );
}

export default AppBarComponent;
