import { CssBaseline, Paper } from "@mui/material";
import React from "react";
import AppBarComponent, {
  AppBarComponentProps,
} from "../landing-page/AppBarComponent";
import Footer from "../landing-page/Footer";
import { ScrollRestoration } from "react-router-dom";

export type LayoutV2Props = AppBarComponentProps & {
  child: React.ReactNode;
  hideFooterNav?: boolean;
  hideFooterResources?: boolean;
};

export default function LayoutV2(props: LayoutV2Props) {
  const { child, hideNavLinks, hideFooterNav, hideFooterResources } = props;
  return (
    <React.Fragment>
      <ScrollRestoration />
      <CssBaseline />
      <AppBarComponent hideNavLinks={hideNavLinks} />
      <Paper
        variant="elevation"
        elevation={0}
        sx={{
          width: "inherit",
          minHeight: "50vh",
        }}
      >
        {child}
        <Footer
          hideNavigation={hideFooterNav}
          hideResources={hideFooterResources}
        />
      </Paper>
    </React.Fragment>
  );
}
