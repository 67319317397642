import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Review from "../Review";
import CashFlowForm from "../CashFlowForm";
import BookingForm from "../BookingForm";
import LayoutV2 from "../layout/LayoutV2";
import { Alert, Container, LinearProgress } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AddOneRoiCalcResultDto,
  MarketingUserDto,
  MarketingUserOneRespDto,
} from "../../sdk/open-api";
import { allowedCountries } from "../../helpers/miscellaneous";
import {
  APPLICATION_COST,
  profitMarginCalculator,
  projectedCashflow,
  returnOnInvestment,
} from "../../helpers/formulae";
import {
  fetchWaitlistUser,
  saveRoiCalculatorResults,
} from "../../services/apis/user.service";

const steps = ["Cash flow data", "Booking data", "Summary"];

export type ROIData = {
  averageMonthlyRevenue: number | null;
  averageMonthlyExpenses: number | null;
  averageMonthlyMarketingSpend: number | null;
  averageMonthlyBookings: number | null;
  averageMonthlyNoShows: number | null;
};

export type ROIDataForStepper = ROIData & {
  setData: React.Dispatch<React.SetStateAction<ROIData>>;
};

function getStepContent(
  step: number,
  currentUser: {
    user: MarketingUserDto | null;
    existing: boolean;
  },
  props: ROIData,
  setData: React.Dispatch<React.SetStateAction<ROIData>>
) {
  switch (step) {
    case 0:
      return (
        <CashFlowForm {...props} user={currentUser.user} setData={setData} />
      );
    case 1:
      return (
        <BookingForm {...props} user={currentUser.user} setData={setData} />
      );
    case 2:
      return <Review {...props} user={currentUser.user} />;
    default:
      throw new Error("Unknown step");
  }
}

export default function ROICalculatorScreen() {
  let location = useLocation();
  const navigate = useNavigate();
  let [urlQueryParameters] = useSearchParams();

  const [currentUser, setCurrentUser] = useState<{
    user: MarketingUserDto | null;
    existing: boolean;
  }>({
    user: null,
    existing: false,
  });

  const [roiData, setRoiData] = useState<ROIData>({
    averageMonthlyRevenue: null,
    averageMonthlyExpenses: null,
    averageMonthlyMarketingSpend: null,
    averageMonthlyBookings: null,
    averageMonthlyNoShows: null,
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    async function setUpUser() {
      const userUuid = urlQueryParameters.get("identifier");
      console.log("ROI_CALC_USER_UUID: ", urlQueryParameters.get("identifier"));

      if (userUuid) {
        // Set the user to the user with corresponding uuid
        try {
          const user = await fetchWaitlistUser({ uuid: userUuid });
          console.log("ROISU User: ", user);

          if (!user) throw new Error("Could not find user");
          if (!user.data) throw user;

          setCurrentUser((_) => {
            return {
              user: user.data as unknown as MarketingUserDto,
              existing: false,
            };
          });
        } catch (e: any) {
          console.warn("ROISU Error: ", e);
          setErrMsg(
            typeof e === "string" ? e : e.message ?? "Something went wrong"
          );
        }
      } else {
        const currentState =
          location.state as unknown as MarketingUserOneRespDto;
        console.log("CURRENT_STATE: ", currentState);

        if (currentState.data) {
          setCurrentUser((_) => {
            return {
              user: currentState.data as unknown as MarketingUserDto,
              existing: currentState.existing ?? false,
            };
          });
        } else {
          setErrMsg("No user found");
        }
      }
    }

    setUpUser();
  }, [location, urlQueryParameters]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const shouldDisableNext = (activeStep: number) => {
    let disabled = false;

    switch (activeStep) {
      case 0:
        disabled =
          !roiData.averageMonthlyRevenue ||
          roiData.averageMonthlyRevenue <= 0 ||
          !roiData.averageMonthlyExpenses ||
          roiData.averageMonthlyExpenses <= 0 ||
          !roiData.averageMonthlyMarketingSpend ||
          roiData.averageMonthlyMarketingSpend <= 0;
        break;
      case 1:
        disabled =
          !roiData.averageMonthlyBookings ||
          roiData.averageMonthlyBookings <= 0 ||
          !roiData.averageMonthlyNoShows ||
          roiData.averageMonthlyNoShows <= 0;
        break;

      default:
        disabled = false;
        break;
    }

    return disabled;
  };

  const saveCalculatorAnalysis = async () => {
    try {
      setLoading(true);
      // First calculate projected data
      const projected = projectedCashflow({
        revenue: roiData.averageMonthlyRevenue ?? 0,
        expenses: roiData.averageMonthlyExpenses ?? 0,
        monthlyMarketingSpend: roiData.averageMonthlyMarketingSpend ?? 0,
        monthlyBookings: roiData.averageMonthlyBookings ?? 0,
        monthlyNoShows: roiData.averageMonthlyNoShows ?? 0,
      });

      const currentProfitMargin = profitMarginCalculator(
        roiData.averageMonthlyRevenue ?? 0,
        roiData.averageMonthlyExpenses ?? 0
      );
      const projectedProfitMargin = profitMarginCalculator(
        projected.revenue,
        projected.expenses
      );
      const projectedROI = returnOnInvestment(
        projectedProfitMargin,
        currentProfitMargin,
        APPLICATION_COST
      );

      // Then create data to save
      const dataToSave: AddOneRoiCalcResultDto = {
        marketing_user_uuid: (currentUser.user as any).uuid! as any,

        // Initial Data
        initial_average_monthly_revenue: roiData.averageMonthlyRevenue ?? 0,
        initial_average_monthly_expenses:
          roiData.averageMonthlyMarketingSpend ?? 0,
        initial_average_monthly_marketing_spend:
          roiData.averageMonthlyMarketingSpend ?? 0,
        initial_average_monthly_bookings: roiData.averageMonthlyBookings ?? 0,
        initial_average_monthly_no_shows: roiData.averageMonthlyNoShows ?? 0,
        initial_profit_margin: Number(currentProfitMargin.toFixed(2)),
        initial_roi: Number((projectedROI * 0.75).toFixed(2)),

        // Projected Data
        projected_average_monthly_revenue: Number(projected.revenue.toFixed(2)),
        projected_average_monthly_expenses: Number(
          projected.expenses.toFixed(2)
        ),
        projected_average_monthly_marketing_spend: Number(
          projected.monthlyMarketingSpend.toFixed(2)
        ),
        projected_average_monthly_bookings: Number(
          projected.monthlyBookings.toFixed(0)
        ),
        projected_average_monthly_no_shows: Number(
          projected.monthlyNoShows.toFixed(0)
        ),
        projected_profit_margin: Number(projectedProfitMargin.toFixed(2)),
        projected_roi: Number(projectedROI.toFixed(2)),
      };
      console.log("SCA Data to Save: ", dataToSave);

      const saved = await saveRoiCalculatorResults(dataToSave);
      console.log("SCA SAVED: ", saved);

      if (!saved) throw new Error("Could not save results");
      if (!saved.data) throw new Error(saved.message ?? "No data returned");

      setLoading(false);

      handleNext();
    } catch (e: any) {
      console.warn("SCA Error: ", e);
      setErrMsg(
        typeof e === "string" ? e : e.message ?? "Something went wrong"
      );
      setLoading(false);
    }
  };

  return (
    <LayoutV2
      hideNavLinks
      hideFooterNav
      hideFooterResources
      child={
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 16, sm: 14 },
            pb: { xs: 8, sm: 10 },
          }}
        >
          {loading && (
            <LinearProgress
              color="primary"
              sx={{ width: "100%", alignSelf: "center" }}
            />
          )}

          {errMsg && (
            <Alert
              variant="filled"
              severity="info"
              color="info"
              onClose={() => setErrMsg("")}
              sx={{
                alignSelf: "center",
                mb: { xs: 1, sm: 2 },
                width: "100%",
                borderRadius: 4,
              }}
            >
              {errMsg}
            </Alert>
          )}

          <Stepper
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
            style={{
              flexWrap: "wrap",
            }}
          >
            {steps.map((label) => (
              <Step key={label} style={{ marginBottom: "10px" }}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {
            <React.Fragment>
              {getStepContent(activeStep, currentUser, roiData, setRoiData)}
              <Box
                sx={{
                  width: "inherit",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {activeStep !== 0 && activeStep !== steps.length - 1 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Take Me Back
                  </Button>
                )}
                {activeStep !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (activeStep === steps.length - 2) {
                        // At this stage, we have all roi-calculator data. Save these.
                        saveCalculatorAnalysis();
                      } else {
                        handleNext();
                      }
                    }}
                    sx={{ mt: 3, ml: 1 }}
                    disabled={shouldDisableNext(activeStep) || loading}
                  >
                    {activeStep === steps.length - 2
                      ? "Show Me My Result. I Can't Wait!"
                      : "Next Step, Please"}
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: { xs: 6, sm: 8 },
                      borderRadius: "8px",
                      textTransform: "none",
                      backgroundColor: AppColours.MainRed,
                      padding: "12px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      return allowedCountries.includes(
                        currentUser?.user!.country?.toLowerCase()
                      )
                        ? navigate("/our-gift", {
                            state: { ...currentUser },
                          })
                        : navigate("/join-coming-soon", {
                            state: { ...currentUser },
                          });
                    }}
                  >
                    Get Our Special Gift To Skip the Queue and Experience
                    Shareduled to the Fullest
                  </Button>
                )}
              </Box>
            </React.Fragment>
          }
          {errMsg && (
            <Alert
              variant="filled"
              severity="error"
              color="error"
              onClose={() => setErrMsg("")}
              sx={{
                alignSelf: "center",
                mb: { xs: 1, sm: 2 },
                width: "100%",
                borderRadius: 4,
              }}
            >
              {errMsg}
            </Alert>
          )}
        </Container>
      }
    />
  );
}
