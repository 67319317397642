import * as React from "react";
import LayoutV2 from "../layout/LayoutV2";
import SummarySection from "../landing-page/SummarySection";
import SolvingPuzzle from "../landing-page/SolvingPuzzleComponent";
import { Divider } from "@mui/material";
import ShareduledAdvantage from "../landing-page/ShareduledAdvantage";
import ServiceExcellence from "../landing-page/ServiceExcellence";
import BusinessFeatures from "../landing-page/BusinessFeatures";
import FAQ from "../landing-page/FAQ";
import ShareduledPower from "../landing-page/ShareduledPower";
import { useEffect, useState } from "react";
import PricingAndStart from "../landing-page/PricingAndStart";
import MarketingModal from "../landing-page/MarketingModal";
import MarketingBanner from "../landing-page/MarketingBanner";

export default function LandingPageScreen() {
  const [open, setOpen] = useState(false);

  const [secondsToOpen, setSecondsToOpen] = useState(5);

  const handleClose = () => {
    setOpen(false);
    setSecondsToOpen(-1);
  };

  useEffect(() => {
    console.log("Expired User Data: ", localStorage.getItem("user_data"));
    localStorage.removeItem("user_data");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsToOpen === 0 && !open) {
        setOpen(true);
        setSecondsToOpen(-1);
      } else if (secondsToOpen > 0) {
        setSecondsToOpen((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsToOpen, open]);

  return (
    <LayoutV2
      child={
        <React.Fragment>
          <MarketingBanner />
          <SummarySection />
          <SolvingPuzzle />
          <Divider />
          <ShareduledAdvantage />
          <Divider />
          <ServiceExcellence />
          <Divider />
          <BusinessFeatures />
          <Divider />
          <FAQ />
          <ShareduledPower />
          <Divider />
          <PricingAndStart />
          <MarketingModal open={open} handleClose={handleClose} />
        </React.Fragment>
      }
    />
  );
}
