import { DateTime } from "luxon";

const lifeTimeAccessEndDate = "2024-09-22T23:59Z";

export const allowedCountries = [
  "canada",
  "united states of america",
  "ca",
  "us",
  "usa",
];

export const routesForBlockerPopUp = [
  "/roi-calculator",
  "/join-for-life",
  "/our-gift",
];

export const scrollToSection = (sectionId: string) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });
  }
};

export function daysRemainingForLifeTimeAccess() {
  const diff = DateTime.fromISO(lifeTimeAccessEndDate).diffNow("days");

  console.log("PROMO_END: ", diff);
  const { values } = diff as any;

  const res = Math.round(values?.days);

  return res < 0 ? 0 : res;
}

export function weeksRemainingForLifeTimeAccess() {
  const diff = DateTime.fromISO(lifeTimeAccessEndDate).diffNow("weeks");

  console.log("PROMO_END_WEEKS: ", diff);
  const { values } = diff as any;

  const res = Math.round(values?.weeks);

  return res < 0 ? 0 : res;
}

/**
 *
 * @param endDateInUTCString defaults now to "2024-09-22T23:59Z"
 * @returns
 */
export function timeRemainingForLifeTimeAccess(
  endDateInUTCString = lifeTimeAccessEndDate
) {
  const endDate = new Date(endDateInUTCString);
  const now = new Date();

  const diff = endDate.getTime() - now.getTime();

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return {
    days: days < 0 ? 0 : days,
    hours: hours < 0 ? 0 : hours,
    minutes: minutes < 0 ? 0 : minutes,
    seconds: seconds < 0 ? 0 : seconds,
  };
}
