import {
  Alert,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { AppColours } from "../../helpers/colors";
import { MarketingUserDto } from "../../sdk/open-api";
import { allowedCountries } from "../../helpers/miscellaneous";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function OurGiftComponent(props: {
  user: MarketingUserDto | null;
  existing: boolean;
  errMsg: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
  disablePayBtn: boolean;
}) {
  const navigate = useNavigate();
  const { user, existing, errMsg, setErrMsg, disablePayBtn } = props;

  return (
    <React.Fragment>
      <Container
        maxWidth="lg"
        id="summary-section"
        sx={{
          minHeight: "inherit",
          justifyContent: "center",
          alignItems: "center",
          pt: { xs: 6, sm: 8 },
          pb: { xs: 8, sm: 10 },
        }}
      >
        <Stack
          spacing={{ xs: 3, sm: 4 }}
          useFlexGap
          sx={{
            height: "inherit",
            width: { xs: "100%", sm: "100%" },
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            pt: { xs: 6, sm: 8 },
          }}
        >
          {errMsg && (
            <Alert
              variant="filled"
              severity="info"
              color="info"
              onClose={() => setErrMsg("")}
              sx={{
                alignSelf: "center",
                mb: { xs: 1, sm: 2 },
                width: "100%",
                borderRadius: 4,
              }}
            >
              {errMsg}
            </Alert>
          )}

          <Typography
            variant="h4"
            sx={{
              display: "flex",
              alignSelf: "center",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Revolutionize Your Appointments with Our&nbsp;
            <Typography
              component="span"
              variant="h4"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
                fontWeight: "bolder",
              }}
            >
              One-Time Exclusive Shareduled Advantage Offer&nbsp;
            </Typography>
            To The Scheduling App &nbsp;
            <Typography
              component="span"
              variant="h4"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
                fontWeight: "bolder",
              }}
            >
              That Pays for Itself&nbsp;
            </Typography>
            for a Steal and&nbsp;
            <Typography
              component="span"
              variant="h4"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
                fontWeight: "bolder",
              }}
            >
              Without Subscriptions (First 200 Only!)
            </Typography>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "500",
              display: "flex",
              alignSelf: (theme) => {
                return {
                  [theme.breakpoints.down("sm")]: {
                    display: "flex-start",
                  },
                };
              },
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Why Pay Monthly when you can totally escape the subscription trap
            and have access for life?
          </Typography>

          <Typography
            variant="body1"
            style={{ fontWeight: "300", textAlign: "left" }}
          >
            Imagine never having to worry about appointment chaos again, where
            scheduling is effortless - seamlessly manages your appointments, and
            keeps your clients coming back—all without the hefty price tag.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontWeight: "300", textAlign: "left" }}
          >
            Think about it: how much time and money do you waste on inefficient
            scheduling systems? Missed appointments, frustrated clients, and
            convoluted platforms all eat away at your bottom line.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontWeight: "300", textAlign: "left" }}
          >
            With Shareduled, those days are over. Our revolutionary scheduling
            software offers the same level of functionality as a custom app for
            a fraction of the price.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontWeight: "300", textAlign: "left" }}
          >
            That's the reality with Shareduled. But here's the shocker: building
            a custom top-notch scheduling app solution would cost you a whopping
            $35,000 at the minimum, not to mention the ongoing maintenance and
            updates! Also, industry competitors charge upwards of $762 per year,
            not including the hidden fees that can add up quickly.
            <br />
            <br />
            Crazy, right?
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              alignSelf: (theme) => {
                return {
                  [theme.breakpoints.down("sm")]: {
                    display: "flex-start",
                  },
                };
              },
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            That’s a lot of money just to keep your business running smoothly,
            but with Shareduled, you get:
          </Typography>

          <List sx={{}}>
            {[
              "Zero Subscription Fees: Save hundreds every year",
              "50% Off all Transaction Fees: Keep more of your earnings and More money in your pocket",
              "Lifetime Access: One-time payment, no hidden costs",
              "No Subscription Fees, Ever: Enjoy continuous savings",
            ].map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "300",
                      textAlign: "left",
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                  >
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              alignSelf: (theme) => {
                return {
                  [theme.breakpoints.down("sm")]: {
                    display: "flex-start",
                  },
                };
              },
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Imagine how much easier your life would be with Shareduled:
          </Typography>

          <List sx={{}}>
            {[
              "Effortlessly manage bookings 24/7",
              "Seamlessly handle cancellations and reschedules",
              "Keep your clients informed with real-time notifications",
            ].map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "300",
                      textAlign: "left",
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                  >
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Plus, as an early adopter, you'll be among the first to influence
            new features and improvements, ensuring that Shareduled always meets
            your needs.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Plus, as an early adopter, you'll be among the first to influence
            new features and improvements, ensuring that Shareduled always meets
            your needs.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            This exclusive offer is limited to the&nbsp;
            <strong>first 200 businesses</strong>&nbsp; and expires on&nbsp;
            <strong>September 22, 2024.</strong>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            But here's the good news: for a limited time, we're offering early
            adopters like you the chance to secure this lifetime access to
            Shareduled, that grants you access to all future updates and
            features, ensuring your scheduling experience never gets left
            behind, FOREVER.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            That's right. No monthly subscriptions, no hidden costs, just
            powerful scheduling for life for just:
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
              color: (theme) => theme?.palette?.primary?.main,
            }}
          >
            $500.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            We believe in our product so much that we're offering you a full
            refund if you're not satisfied after a year. Plus, we'll throw in an
            extra month for free. All we ask is that you share your honest
            feedback with us.
            <br />
            <br />
            Amazing, right?
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Here's a summary of what you get again:
          </Typography>

          <List sx={{}}>
            {[
              "Early Bird Perks: Be one of the first 200 users and enjoy 50% off transaction fees, forever",
              "Satisfaction Guaranteed: Give us a year to impress you. If we don’t, we’ll refund every penny – plus a bonus month of service.",
              "Exclusive Offer: Only the first 200 innovators will lock in this lifetime deal for just $500.",
              "Countdown to Savings: Act fast! This deal expires on September 22, 2024.",
            ].map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "300",
                      textAlign: "left",
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                  >
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Now is the perfect time to secure your spot and transform your
            scheduling experience forever.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
            }}
          >
            Don't miss your chance to revolutionize your business and achieve
            scheduling nirvana at a fraction of the usual cost.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "300",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "left",
              mb: { xs: 4, sm: 5, md: 6 },
            }}
          >
            Join our waitlist today and become a part of the Shareduled family.
          </Typography>

          <Button
            color="primary"
            variant="contained"
            fullWidth
            disabled={disablePayBtn}
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              backgroundColor: AppColours.MainRed,
              padding: "12px",
              fontSize: "24px",
            }}
            onClick={() => {
              return user && user.country
                ? allowedCountries.includes(user.country.toLowerCase())
                  ? navigate("/join-for-life", {
                      state: { user, existing },
                    })
                  : navigate("/join-coming-soon", {
                      state: { user, existing },
                    })
                : navigate("/welcome");
            }}
          >
            Yes! Secure My Lifetime Spot Now for $500!
          </Button>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
