import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function PricingPolicies() {
  return (
    <Stack spacing={{ xs: 2, sm: 3 }} useFlexGap>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        1. Transparency and Flexibility
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        At Shareduled, we strive to be transparent about our pricing structure
        and offer flexible options to meet your needs. This policy outlines the
        general principles governing our pricing for the Service. Specific
        pricing details, including any tiered structures or additional fees,
        will be clearly displayed during the signup process or communicated
        directly to you for custom quotes.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        2. Value-Based Pricing
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        Our pricing reflects the value we deliver through the Service. We
        consider factors such as the features you access, the level of support
        you require, and the volume of usage. This ensures a fair and
        competitive price that aligns with the benefits you receive.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        3. Multiple Payment Options
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We offer a variety of secure payment options to accommodate your
        preferences. These include credit cards, debit cards, and online payment
        services such as PayPal and Stripe. We will clearly communicate accepted
        payment methods during the signup process.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        4. Free Trials and Money-Back Guarantees (where applicable)
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We may offer free trials or money-back guarantees for certain plans or
        services. These will be clearly outlined on our website or in your
        specific quote. Free trials may require a valid payment method to be
        registered upfront, but you will not be charged unless you choose to
        continue using the Service after the trial period ends.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        5. Special Limited-Time Offer
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        For a limited time, Shareduled is offering a lifetime access plan for a
        one-time payment of $500. This offer includes:
      </Typography>

      <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
        {[
          "Zero subscription fees",
          "50% off the usual transaction fee",
          "Lifetime access to all features and updates",
          "A full refund and an extra month free if you decide it's not for you within the first year",
        ].map((x, index) => (
          <ListItem disablePadding key={index.toString()}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2" style={{ fontWeight: "300" }}>
                {x}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        This special offer is available only to the first 200 users and expires
        at 11:59pm EST on Sunday September 22, 2024. Our product launch date is
        Friday September 27, 2024, 2024.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        6. Changes to Pricing Policy
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We reserve the right to modify our pricing policy at any time. We will
        make reasonable efforts to notify existing users of any significant
        changes in advance. Your continued use of the Service after the
        effective date of any pricing change constitutes your acceptance of the
        revised policy.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        7. Refund Policy
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We are committed to your satisfaction. If you are not satisfied with the
        Service within the first year, we will provide a full refund and an
        extra month free. To request a refund, please contact our support team
        at support@shareduled.com with your account details and reason for the
        refund.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        8. Discounts and Promotions
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        From time to time, we may offer discounts or promotions. These will be
        clearly communicated on our website, through our marketing materials, or
        directly to you. Please note that such offers may be subject to
        additional terms and conditions.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        9. Payment Security
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We prioritize the security of your payment information. All transactions
        are processed through secure payment gateways that comply with industry
        standards for encryption and fraud prevention. Your payment details are
        never stored on our servers.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        10. Contact Information
      </Typography>

      <Typography
        variant="body1"
        sx={{ fontWeight: "300", mb: { xs: 4, sm: 5, md: 6 } }}
      >
        If you have any questions about our Pricing Policy, please contact us
        at&nbsp;
        <Link
          color="inherit"
          href="mailto:support@shareduled.com?subject=About Shareduled Pricing Policy&body=Hello Team Shareduled,"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@shareduled.com
        </Link>
      </Typography>
    </Stack>
  );
}
